<template>
    <div class="wrapper">
        <div class="finalize-register">
            <header class="finalize-register_header">
                <p>
                    Finalize registration
                </p>
            </header>
            <main class="finalize-register_body">
                <div class="finalize-register_row">
                    <hint-info
                        text="Your profile cannot be seen by other users."
                    />
                </div>
                <div class="finalize-register_row">
                    <div class="finalize-register_col finalize-register_col--avatar">
                        <div class="finalize-register_avatar">
                            <img
                                :src="avatar_url"
                                alt=""
                            >
                        </div>
                    </div>
                </div>
                <!-- <div class="finalize-register_row">
                    <div class="finalize-register_col finalize-register_col--switch">
                        <form-switch
                            :id="'stay_anonymous'"
                            v-model="stay_anonymous"
                        />
                        <span class="switch_text">
                            I would like to remain anonymous
                        </span>
                    </div>
                </div> -->
                <div
                    v-if="!stay_anonymous"
                    class="finalize-register_row"
                >
                    <div class="finalize-register_col">
                        <form-input
                            v-model="$v.firstname.$model"
                            legend="Firstname"
                            type="text"
                            name="firstname"
                            :field.sync="firstname"
                            :errors="validationMessage($v.firstname)"
                            :is-valid="$v.firstname.$dirty && !$v.firstname.$anyError"
                            @input.native="$v.firstname.$touch();"
                            @blur="$v.firstname.$touch();"
                        />
                    </div>
                    <div class="finalize-register_col">
                        <form-input
                            v-model="$v.lastname.$model"
                            legend="Lastname"
                            type="text"
                            name="lastname"
                            :field.sync="lastname"
                            :errors="validationMessage($v.lastname)"
                            :is-valid="$v.lastname.$dirty && !$v.lastname.$anyError"
                            @input.native="$v.lastname.$touch();"
                            @blur="$v.lastname.$touch();"
                        />
                    </div>
                </div>
                <div class="finalize-register_row">
                    <div class="finalize-register_col">
                        <email
                            :email-value="getterOauthFormData.email"
                        />
                    </div>
                    <div class="finalize-register_col">
                        <div class="password_field">
                            <form-input
                                v-model="$v.password.$model"
                                legend="Password"
                                prepend-icon="LockIcon"
                                :append-icon="showPassword ? 'EyeIcon' : 'EyeOffIcon'"
                                :type="showPassword ? 'text' : 'password'"
                                name="password"
                                :maxlength="20"
                                autocomplete="new-password"
                                :field.sync="password"
                                :errors="validationMessage($v.password)"
                                :is-valid="$v.password.$dirty && !$v.password.$anyError"
                                @clicked="showPassword = !showPassword"
                                @input.native="$v.password.$touch()"
                                @blur="$v.password.$touch(); passwordInFocus = false"
                                @focus="passwordInFocus = true"
                            />

                            <div
                                v-if="passwordInFocus && $v.password.$anyError"
                                class="password-validation"
                            >
                                <password-validator-helper :password="password" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="finalize-register_row">
                    <div class="finalize-register_col">
                        <div class="finalize-register_checkbox">
                            <form-checkbox
                                v-model="avatar"
                                color="orange darken-2"
                                :label="'Use my avatar'"
                            />
                        </div>
                    </div>
                </div>
                <div class="finalize-register_row">
                    <div class="finalize-register_col">
                        <form-checkbox
                            v-model="chk_email_marketing"
                            class="agree_email"
                            color="orange darken-2"
                            :label="'Agree to receive e-mails (newsletter, discount codes, promotional materials.)'"
                        />
                    </div>
                </div>
            </main>
            <footer class="finalize-register_footer">
                <button
                    class="btn-base btn-main button-width-100"
                    :class="{'btn-disabled': !isFormValid}"
                    @click="validate"
                >
                    <template v-if="loading">
                        <button-loader />
                    </template>
                    <span v-else>Confirm data</span>
                </button>
            </footer>
            <!-- avatar -->
        </div>
        <p class="agree_term">
            By creating an account, you agree to the <br>
            <router-link :to="{name: 'terms'}">
                Terms and Conditions
            </router-link>and
            <router-link :to="{name: 'privacy'}">
                Privacy Policy.
            </router-link>
        </p>
    </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { validationMessage } from 'vuelidate-messages'
import { eventBus } from '@/helpers/event-bus/'
import {
    createNamespacedHelpers,
    mapGetters
} from 'vuex'
// types
import {
    ACTION_REGISTER_OAUTH
} from '@/store/modules/client/action-types'
// validation
import formValidationMixin from '@/mixins/formValidationMixin'
import { validationRules } from '@/validation/register/Rules'
import { formMessages } from '@/validation/register/Messages'

import HintInfo from '@/components/common/Hint'
import PasswordValidatorHelper from '@/components/PasswordValidatorHelper';
// import PhoneNumber from '@/components/common/form-elements/phone/PhoneNumber';
import ButtonLoader from '@/components/common/ButtonLoader.vue'
import Email from '@/components/common/form-elements/email/Email';

const { mapActions: mapClientActions } = createNamespacedHelpers('client')

export default {
    name: 'FinalizeOAuth',
    components: {
        PasswordValidatorHelper,
        // PhoneNumber,
        HintInfo,
        ButtonLoader,
        Email
    },
    mixins: [
        validationMixin,
        formValidationMixin
    ],
    validations: {
        ...validationRules
    },
    data() {
        return {
            firstname: '',
            lastname: '',
            stay_anonymous: false,
            // Email
            loadEmail: false,
            password: '',
            avatar: true,
            avatar_url: null,
            chk_email_marketing: true,
            passwordInFocus: false,
            showPassword: false,
            phoneValidationStatus: false,
            loading: false
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterOauthFormData'
        ]),
        ...mapGetters('email', [
            'isEmailVerified',
            'getEmailObject'
        ]),
        isFormValid() {
            return !this.$v.$invalid && this.isEmailVerified
        }
    },
    async created() {
        if (!this.getterOauthFormData || !this.getterOauthFormData.social_id) {
            this.$router.push({ name: 'login' })
            return
        }
        this.firstname = this.getterOauthFormData.firstname
        this.lastname = this.getterOauthFormData.lastname
        this.avatar_url = this.getterOauthFormData.avatar
    },
    methods: {
        validationMessage: validationMessage(formMessages),
        ...mapClientActions([
            ACTION_REGISTER_OAUTH
        ]),
        validatePhone(value) {
            this.phoneValidationStatus = value
        },
        async submit() {
            try {
                this.loading = true
                // Create client
                await this[ACTION_REGISTER_OAUTH]({
                    firstname: this.stay_anonymous ? null : this.firstname,
                    lastname: this.stay_anonymous ? null : this.lastname,
                    password: this.password,
                    avatar: this.avatar,
                    provider: this.getterOauthFormData.platform,
                    social_id: this.getterOauthFormData.social_id,
                    token: this.getterOauthFormData.token,
                    stay_anonymous: true.stay_anonymous,
                    chk_email_marketing: this.chk_email_marketing,
                    ...this.getEmailObject
                })
                eventBus.$emit('showSnackBar', 'Your account has been created', 'success')
                this.$router.push({ name: 'auth-intermediate' })
            } catch (err) {
                eventBus.$emit('showSnackBar', err, 'error')
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
    .finalize-register{
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 10%);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 20%);
        padding: 20px;
        border-radius: 4px;
        margin: 40px 0;
        margin-bottom: 10px;
        &_header{
            font-weight: 700;
            font-size: 24px;
            line-height: 40px;
            margin-bottom: 5px;
        }
        &_row{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            margin: 20px 0;

            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: 0;
            }
            .hint-wrapper{
                margin: 0;
                .hint-image{
                    margin-right: 0;
                }
            }
        }
        &_col {
            width: calc(50% - 15px);
            &--avatar{
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            @media all and (max-width: 768px) {
                width: 100%;
                &:first-child {
                    margin-bottom: 20px;
                }
                &--avatar{
                    margin-bottom: 0 !important;
                }
            }
            .phone-verification__btn{
                button{
                    width: 100% !important;
                }
            }
            &--switch{
                display: flex;
                .switch_text{
                    margin-left: 20px;
                }
            }
        }
        &_checkbox{
            display: flex;
            align-items: center;
            & > span {
                margin-left: 10px;
            }
        }
        &_avatar{
            width: 80px;
            height: 80px;
            background: grey;
            border-radius: 50%;
            overflow: hidden;
            & > img {
                max-width: 100%;
            }
        }
        &_footer{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .agree_term{
        font-size: 14px;
        margin-bottom: 20px;
        text-align: center;
    }
    .agree_email{
        margin-top: 0;
        margin-bottom: 0;
    }
</style>
