<template lang="html">
    <section>
        <reset-password-form />
    </section>
</template>

<script>
// Elements
import ResetPasswordForm from '@/components/reset_password/ResetPasswordForm';

export default {
    components: {
        ResetPasswordForm
    },
    metaInfo: {
        title: 'Reset password'
    }
}
</script>
