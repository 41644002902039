<template lang="html">
    <div class="">
        <loading-overlay />
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import {
    ACTION_CLIENT_SOCIAL_LOGIN_VERIFY
} from '@/store/modules/client/action-types'

const {
    mapActions: mapClientActions
} = createNamespacedHelpers('client');

export default {
    async created() {
        await this.verifyClient()
    },
    methods: {
        ...mapClientActions({
            ACTION_CLIENT_SOCIAL_LOGIN_VERIFY
        }),
        async verifyClient() {
            try {
                const data = await this[ACTION_CLIENT_SOCIAL_LOGIN_VERIFY]({
                    code: this.$route.query.code,
                    provider: this.$route.params.service
                })
                this.$router.push({ name: data.route_name })
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
                setTimeout(() => {
                    this.$router.push({ name: 'login' })
                }, 3000);
            }
        }
    }
}
</script>
