<template lang="html">
    <div class="successful-payment">
        <div class="successful-payment__container">
            <successful-payment-icon />
            <p class="successful-payment__title">
                <span>Great success!</span>
            </p>
            <p class="successful-payment__text">
                Saved! You can log in now.
            </p>
            <div class="successful-payment__buttons">
                <custom-button
                    default
                    class="button btn-base_colored"
                    :type="'submit'"
                    @on-btn-click="$router.push({ name: 'login' })"
                >
                    login
                </custom-button>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/helpers/event-bus/'
import SuccessfulPaymentIcon from '@/components/icons/SuccessfulPaymentIcon.vue';

export default {
    name: 'ResetPasswordSuccess',
    components: {
        SuccessfulPaymentIcon
    },
    mounted() {
        if (!this.$route.params.token) {
            eventBus.$emit(
                'showSnackBar',
                'The link has expired.',
                'info'
            )
            this.$router.push({ name: 'login' })
        }
    }
}
</script>
<style lang="scss">
.successful-payment {
    width: 100vw;
    height: 70vh;
    min-height: 700px;
    min-width: 375px;
    background-color: #fafafa;
    &__container {
        max-width: 900px;
        height: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
            margin-left: -28px;
        }
    }
    &__title {
        font-style: normal;
        font-weight: 900;
        font-size: 56px;
        line-height: 70px;
        margin-top: 40px;
        margin-bottom: 20px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }
    &__text {
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 40px;
    }
    &__buttons {
        margin-top: 40px;
        display: flex;
    }

    & .button:first-child {
        margin-right: 20px;
    }
}
</style>
